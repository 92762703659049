import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { routes } from '../constants/routes';
import Home from '../pages/Home';

const RouterConfig = () => {
  return (
    <Routes>
      <Route path={`${routes.home}`} element={<Home />}/>
    </Routes>
  )
}

export default RouterConfig;