import { faCoins, faExclamationTriangle, faFilter, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import Filter from '../components/filter'
import Reward from '../components/reward'
import { addReward, getCart, getRewards, removeReward } from '../packages/api/store/product'
import htmlParser from 'html-react-parser'
import { IReward } from '../interfaces/IRewards'
import { useDebounce } from '../packages/hooks'
import Modal from '../components/Modal'
import jwtDecode from 'jwt-decode'
import { createOrderToken } from '../packages/api/order'

const Home = () => {

  const [view, setView] = useState<{ status: boolean, value: number, isInCart: boolean }>({ status: false, value: 0, isInCart: false });
  const [rewards, setRewards] = useState<any[]>([]);
  const [cart, setCart] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false)
  const [category, setCategory] = useState([]);
  const [[min, max], setPriceRange] = useState([0, 0]);
  const [brand, setBrand] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [page, setPage] = useState(1);
  const [scrollPointReached, setScrollPointReached] = useState(false);
  const [blockLoading, setBlockLoading] = useState({ block: true, type: 'prev' });
  const [userData, setUserData] = useState<any>({});
  const [totalPoints, setPoints] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ status: true, message: '', type: '' });
  const [count, setCount] = useState(0);
  const [filterLoading, setFilterLoading] = useState<boolean>(false);

  //product dimentions
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  //container dimentions
  const [containerDimentsion, setContainerDimentsion] = useState({ width: 0, height: 0 })

  const [error, setError] = useState({ status: false, type: '', message: '' })

  const debouncedScroll = useDebounce(scrollPosition, 300);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');


  const containerRef = useCallback(node => {
    if (node !== null) {
      setContainerDimentsion({ width: node.getBoundingClientRect().width, height: node.getBoundingClientRect().height });
    }
  }, []);

  const updateCart = (id: number, points: number) => {
    const selectedReward = rewards.filter(el => el.id === id)[0];
    const isPresent = cart.filter(el => el.id === id);
    if (totalPoints >= points && token) {
      if (isPresent.length <= 0) {
        addReward(token || '', id).then(res => {
          setPoints(e => e - points)
          setCart(el => [...el, selectedReward]);
        }).catch(e => {
          setModalOpen(true);
          setErrorMessage({ status: true, message: e?.response?.data?.data?.message === "Wrong reward selection" ? 'Not enough points.' : 'Please try again.', type: 'error' })
        })
      }
    } else {
      setModalOpen(true);
      setErrorMessage({ status: true, message: 'Not enough points.', type: 'error' })
    }
  }

  const deleteItem = (id: number, points: number) => {
    const isPresent = cart.filter(el => el.id === id);
    if (isPresent.length > 0 && token) {
      removeReward(token || '', id).then(res => {
        setPoints(e => e + points)
        const newItems = cart.filter(el => el.id !== id);
        setCart(newItems)
        setView({ status: view.status, value: view.value, isInCart: false })
      }).catch(e => {
        setModalOpen(true);
        setErrorMessage({ status: true, message: 'Please try again.', type: 'error' })
      })
    }
  }

  const loadProducts = async (page: number = 1, filters?: any) => {
    await getRewards(token || '', page, filters || null).then(res => {
      const response = res.data.data;
      setBlockLoading({ block: response.links.next === null, type: 'next' });
      setCount(response?.total_count || response?.results?.length || 0)
      setRewards(r => rewards.length > 0 && page !== 1 ? [...r, ...response.results] : response.results)
      setScrollPointReached(false)
      setError({ status: false, message: error.message, type: error.type })
      setIsLoading(false)
      setFilterLoading(false)
    }).catch(error => {
      setPage(page - 1)
      if (error?.response?.data?.data?.message === "Signature has expired") {
        setError({ status: true, type: 'token', message: "Token expired. Please try again" || 'Something went wrong' });
      } else {
        setError({ status: true, type: 'api', message: 'Something went wrong' });
      }
    })
  }

  const redeemRewards = () => {
    createOrderToken(token || '').then(res => {
      const response = res.data
      if (response) {

        const OToken = response?.message?.order_token || null
        console.log(response);
        
        if (token && token !== null) {
          window.location.replace(userData.redirect_uri + '?token=' + OToken)
        } else {
          setError({status: true, type: 'ot', message: 'Please try again'})
        }

      } else {
        setError({status: true, type: 'ot', message: 'Please try again'})
      }

    }).catch((e: any) => {
      setError({status: true, type: 'ot', message: 'Please try again'})
    })
    // window.location.replace(userData.redirect_uri + '?token=' + token)

  }

  useEffect(() => {
    setIsLoading(true)
    if (token) {
      let decoded: any;
      try {
        decoded = jwtDecode(token);
        setUserData(decoded)
        setPoints(decoded?.range_points);
      } catch {
        decoded = null
      }
      getCart(token).then((res: any) => {
        const cartItems = res?.data?.data?.data || [];
        let totalP = 0;
        if (cartItems.length > 0) cartItems.map((e: any) => totalP += e.points); else totalP += 0;
        setCart(cartItems)
        setPoints(decoded?.range_points - totalP)
        if (rewards.length === 0) {
          loadProducts();
        }
      }).catch(e => {
        setError({ status: true, type: 'token', message: 'Invalid Token' });
      })
    } else {
      setError({ status: true, type: 'token', message: 'Invalid Token' });
    }
  }, [token])

  useEffect(() => {
    if (scrollPointReached && rewards.length / 30 <= page && !blockLoading.block && rewards.length >= 30) {
      if ((brand.length > 0 || category.length > 0) && token) {
        setPage(page + 1)
        loadProducts(page + 1, {
          categories: category,
          brands: brand,
          points_range: [min, max]
        });
      } else {
        setPage(page + 1)
        loadProducts(page + 1);
      }
    }
  }, [scrollPointReached])

  useEffect(() => {
    if (view.status) {
      const isPresent = cart.filter(el => el.id === rewards[view.value].id);
      if (isPresent.length > 0) {
        setView({ status: view.status, value: view.value, isInCart: true });
      } else {
        setView({ status: view.status, value: view.value, isInCart: false });
      }
    }
  }, [cart, view.value])

  useEffect(() => {
    if (dimensions && containerDimentsion && debouncedScroll && rewards.length >= 30) {
      const itemsInRow = Math.floor(containerDimentsion.width / dimensions.width);
      const totalHeightOfContainer = Math.ceil((dimensions.height * rewards.length) / itemsInRow);
      const totalRows = Math.ceil(totalHeightOfContainer / dimensions.height);
      const rowsScrolled = Math.round(debouncedScroll / dimensions.height) + Math.round(containerDimentsion.height / dimensions.height);
      if (totalRows <= rowsScrolled) {
        setScrollPointReached(true);
      }
    }

  }, [debouncedScroll])

  useEffect(() => {
    if (brand.length > 0 || category.length > 0 || min > 0 || max > 0) {
      setFilterLoading(true)
      setPage(1)
      loadProducts(1, {
        categories: category,
        brands: brand,
        points_range: [min, max]
      })
    }
  }, [brand, category, min, max])

  return (
    <>
      {isLoading ?
        <div className={`fixed w-screen h-screen z-[400]  top-0 right-0 flex items-center justify-center flex-col bg-[#0A6AA6]/75`}>
          <div>
            {error.status ? <FontAwesomeIcon icon={faExclamationTriangle} color='#fff' size='3x' /> : <FontAwesomeIcon spin icon={faSpinner} color='#fff' size='3x' />}
          </div>
          <h1 className='text-white my-2 text-2xl font-semibold'>{error.status ? error.message : 'Getting rewards...'}</h1>
        </div> :
        <main className={`md:grid-cols-[20%_78%] transition-[display] w-screen overflow-hidden grid gap-[1%] p-[1%]`}>
          <div className='my-1 rounded drop-shadow bg-white py-1 px-2  md:hidden flex items-center justify-between'>
            <div className='rounded-full w-[47.5%] text-sm font-medium flex items-center justify-center'>
              <h3>Balance:</h3>
              <div className='mx-1'>
                <FontAwesomeIcon color='#E1B530' icon={faCoins} />
                <span className='mx-2'>{totalPoints || 0}</span>
              </div>
            </div>
            <div className='w-[2px] bg-[#000000]/25 h-full'></div>
            <div className='flex w-[47.5%] text-[#000000]/50 items-center justify-center'>
              <FontAwesomeIcon icon={faFilter} />
              <span className='mx-2'>Filters</span>
            </div>
          </div>
          <div className='md:inline-block hidden'>
            <Filter showError={(e) => setError({ status: e, type: 'token', message: 'Invalid Token' })} filterLoading={filterLoading} setFilterLoading={(e: boolean) => setFilterLoading(e)} cart={cart} view={view} setBrand={(e: any) => setBrand(e)} setCategory={(e: any) => setCategory(e)} setPriceRange={(e: any) => setPriceRange([parseInt(e.min), parseInt(e.max)])} brand={brand} category={category} min={min} max={max} /> : <></>
          </div>
          <div className={`${cart.length > 0 ? 'h-[65vh]' : 'h-screen'} hide-scroll-bar h-full transition-all bg-white rounded shadow shadow-slate-900/10 p-3`}>
            <div className='flex items-center justify-between'>
              <h2 className='text-xl font-semibold'>Rewards <span className='text-xs font-normal text-[#000000]/50'>({count} items)</span></h2>
              <div className='rounded-full text-sm font-medium hidden md:flex items-center'>
                <h3>Balance:</h3>
                <div className='mx-1'>
                  <FontAwesomeIcon color='#E1B530' icon={faCoins} />
                  <span className='mx-2'>{totalPoints || 0}</span>
                </div>
              </div>
            </div>
            {rewards.length > 0 ? <div style={{ height: `calc(${cart.length > 0 ? '65vh' : '100vh'} - 50px)` }} ref={containerRef} onScroll={(e: any) => setScrollPosition(e.target.scrollTop)} className={`md:grid-cols-3 lg:grid-cols-4 overflow-y-auto transition-[display] w-full grid grid-cols-2 gap-2`}>
              {rewards.map((item, index) => {
                return (
                  <Reward setDimension={(e: any) => setDimensions(e)} key={index} data={item} index={index} onViewClick={(e: number) => setView({ status: true, value: e, isInCart: view.isInCart })} addToCart={() => updateCart(item.id, item.points)} view={view} />
                )
              })}
            </div> : <div className='w-full h-full flex items-center justify-center'>
              <h3 className='text-2xl font-semibold'>No products found</h3>
            </div>}

          </div>
          {cart.length > 0 ? <div className='mb-2 bg-white rounded shadow shadow-slate-900/10  flex flex-col col-span-full'>
            <div className='w-screen flex justify-between items-center p-3'>
              <div className='flex flex-row items-center overflow-x-auto w-[50vw] md:w-[75vw] my-2 p-2'>
                {cart.map((el: IReward, index: number) => {
                  return (
                    <div style={{ minWidth: '250px' }} key={index} className="rounded w-40 md:w-80 h-90 relative border-[1px] border-slate-900/10 flex items-center justify-between p-2 cursor-pointer hover:border-[#0A6AA6] transition-all mx-2">
                      <div className='w-[30%] h-auto md:w-16 md:h-16 flex items-center justify-center'>
                        <img width="100%" height="100%" alt={el.title} src={el.image} />
                      </div>
                      <h1 className='w-[65%] reward-title md:text-ellipsis md:overflow-hidden hidden md:inline-block text-sm'>{el.title}</h1>
                      <button onClick={() => deleteItem(el.id, el.points)} className='absolute -top-[10px] -right-[10px] rounded-full bg-white hover:bg-slate-100 flex items-center justify-center w-6 h-6 transition-all'>
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                  )
                })}
              </div>
              <div className=' md:w-[20vw] h-full flex items-center justify-center'>
                <button onClick={redeemRewards} className='bg-[#0A6AA6] text-white py-1 px-4 font-medium rounded m-2 '>Redeem now</button>
              </div>
            </div>
          </div> : <div className='w-full h-full flex items-center justify-center'>
          </div>}
          {view.status ? <Modal onClose={() => setView({ status: false, value: view.value, isInCart: view.isInCart })} open={view.status}>
            <div className='w-3/4 h-3/4 bg-white rounded-lg drop-shadow'>
              <div style={{ height: 'calc(100% - 75px)' }} className='w-full flex flex-col md:flex-row  items-start justify-between p-3'>
                <div className='w-full md:w-2/4 h-2/4 md:h-full p-8 overflow-hidden my-1 flex items-center justify-center'>
                  <img className='group-hover:scale-110 transition-all inline-block' width='75%' height='75%' src={rewards[view.value].image} alt={rewards[view.value].title} />
                </div>
                <div className='w-full md:w-2/4 h-2/4 md:h-full overflow-y-auto p-2'>
                  <h2 className='text-xl font-semibold mt-10 mb-2'>{rewards[view?.value]?.title}</h2>
                  <h3 className='text-base font-medium text-gray-400 my-2'>Brand: <span className='text-black'>{rewards[view.value].brand}</span></h3>
                  <div className='my-3 text-lg font-medium'>
                    <FontAwesomeIcon color='#E1B530' icon={faCoins} />
                    <span className='mx-2'>{rewards[view.value].points}</span>
                  </div>
                  <button className={`transition-all rounded bg-[#0A6AA6] text-white px-3 py-1 my-4 font-medium`} onClick={() => view.isInCart ? deleteItem(rewards[view.value].id, rewards[view.value].points) : updateCart(rewards[view.value].id, rewards[view.value].points)}>{view.isInCart ? 'Remove reward' : 'Select reward'}</button>
                  <div className={`text-base mt-4 my-2`}>{htmlParser(rewards[view.value].description)}</div>
                  <div className='my-2'>
                    {rewards[view.value].features.filter((el: any) => el.key !== "Brand").map((el: any, index: number) => (
                      <div key={index} className='grid grid-cols-3'>
                        <div className='text-gray-400'>{el.key}</div>
                        <div className='col-span-2'>{el.value}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='w-full flex items-center justify-end px-4'>
                <button onClick={() => setView({ status: false, value: view.value, isInCart: view.isInCart })} className='transition-all rounded bg-[#0A6AA6] text-white px-3 py-1 my-4 font-medium'>Done</button>
              </div>
            </div>
          </Modal> : <></>}
        </main>}
      {modalOpen && errorMessage.status ? <Modal open={modalOpen}>
        <div className='w-72 bg-white rounded-lg drop-shadow p-2'>
          <div>
            <h3 className='text-2xl font-medium'>{errorMessage?.message}</h3>
          </div>
          <div className='w-full flex item-center justify-end'>
            <button onClick={() => setModalOpen(false)} className='bg-[#0A6AA6] text-white rounded py-1 px-2 outline-none font-medium'>Close</button>
          </div>
        </div>
      </Modal> : <></>}
      {/* <div className={`w-full bg-white fixed bottom-0 right-0 transition-all duration-300 overflow-hidden ${cart.length > 0 ? 'h-36 opacity-100 p-4' : 'h-0 opacity-0 p-0'}`}>
        
      </div> */}
    </>
  )
}

export default Home