import React from 'react'

const Modal = (props: {children: any, open: boolean, onClose?: any}) => {
  return (
    <div className={`w-screen h-screen z-50 fixed top-0 left-0 transition-all ease-in-out ${props.open ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
      <div onClick={props.onClose} className='w-full h-full absolute top-0 left-0 bg-[#000000]/25 -z-10'></div>
      <div className='w-full h-full flex items-center justify-center z-50'>
        {props.children}
      </div>
    </div>
  )
}

export default Modal