import React from 'react'
import Logo from '../Logo'

const Navbar = () => {
  return (
    <header className='w-screen items-center justify-center py-2 px-4 bg-white shadow shadow-slate-900/10'>
      <Logo />
    </header>
  )
}

export default Navbar