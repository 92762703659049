import { faCoins, faEye, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'
import { IReward } from '../../interfaces/IRewards'

const Reward = (props: { data: IReward, onViewClick: any, addToCart: any, view: { status: boolean, value: number }, index: number, setDimension: any }) => {

  const ref: any = useCallback((node: any) => {
    if (node !== null) {
      props.setDimension({ width: node.getBoundingClientRect().width, height: node.getBoundingClientRect().height })
    }
  }, []);


  return (
    <div ref={ref} className='group w-full bg-white rounded transition-all p-2 shadow-none hover:shadow hover:shadow-slate-900/10 flex flex-col items-start justify-between h-[22rem]' >
      <div className='h-[270px] w-full'>
        <div className='w-full h-[60%] p-4 overflow-hidden my-1 flex items-center justify-center cursor-pointer' onClick={() => props.onViewClick(props.index)}>
          <div className='w-full h-full flex items-center justify-center'>
            <img className='group-hover:scale-110 transition-all inline-block' width='100%' height='100%' src={props.data.image} alt={props.data.title} />
          </div>
        </div>
        <div className="cursor-pointer w-full" onClick={() => props.onViewClick(props.index)}>
          <div className='w-full flex items-center justify-between'>
            <h3 className='text-sm whitespace-nowrap text-[#535766] font-medium my-1'>{props.data.brand}</h3>
            <div className='my-1 text-sm font-semibold'>
              <FontAwesomeIcon color='#E1B530' icon={faCoins} />
              <span className='mx-2'>{props.data.points}</span>
            </div>
          </div>
          <h1 className='reward-title text-base text-ellipsis overflow-hidden font-semibold text-[#282c3f]'>{props.data.title}</h1>
        </div>
      </div>
      <button className={`${props?.view?.status && props?.view?.value === props.index ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto'} ${props.view.status ? 'delay-75' : null} transition-all w-full rounded bg-[#0A6AA6] text-white py-2 my-2 font-semibold text-sm`} onClick={() => props.addToCart()}>
        <FontAwesomeIcon icon={faShoppingCart} />
        <span className='mx-2 font-bold'>Add to Cart</span>
      </button>
    </div>
  )
}

export default Reward;