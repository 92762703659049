import axios from "axios"
import { env } from "../../../constants/env"

export const createOrderToken = async (token: string) => {
  try {
    const response = await axios.post(`https://exact-papaya.accoladez.com/api/v1/auth/client/order/token`, {}, {
      headers: {
        "r-token": token,
        "X-API-KEY": env.X_API_KEY,
        "X-API-SECRET": env.X_API_SECRET
      }
    })

    return response
  } catch (e) {
    throw e
  }
}