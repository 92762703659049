import React, { useState } from 'react'
import "rc-slider/assets/index.css";
import { IFilters } from '../../interfaces/IFilters';
import { useEffect } from 'react';
import { getFilters } from '../../packages/api/store/product';
import { useSearchParams } from 'react-router-dom';
import { LoadingScreen } from '../../packages/utility';

const Filter = (props: { view: { status: boolean, value: number | string }, setPriceRange: any, setCategory: any, setBrand: any, category: any, brand: any, max: number, min: number, cart: any[], filterLoading: boolean, setFilterLoading: any, showError: (error: boolean) => void }) => {
  const [filters, setFilters] = useState<IFilters>({
    category: [],
    brands: [],
    points: {
      max: 0,
      min: 0
    }
  });
  const [selectedFilters, setSelectedFilters] = useState<{ categories: number[], brands: number[], priceRange: number[] }>({
    categories: [],
    brands: [],
    priceRange: [0, 0]
  })
  // const [clear, setClear] = useState(true);
  const [priceRange, setPriceRange] = useState<number[]>([])
  const [[customMin, customMax], setCustom] = useState<number[]>([0, 0]);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const priceChangeFilter = (min: number, max: number) => {
    props.setPriceRange({ min: min, max: max })
    setSelectedFilters({
      categories: selectedFilters.categories,
      priceRange: [min, max],
      brands: selectedFilters.brands
    })

  }

  const changeFilterState = (filter: {
    category?: number,
    brand?: number,
    priceRange?: {
      min: number,
      max: number
    }
  }) => {
    props.setFilterLoading(true);
    const changedFilter = filter;
    setSelectedFilters((state: any) => {
      let affectedState = state;
      if (changedFilter.category) {
        const indexOfGivenCategory = affectedState.categories.indexOf(changedFilter.category)
        if (indexOfGivenCategory > -1) {
          const filteredCategories = affectedState.categories.filter((x: any) => x !== changedFilter.category)
          affectedState = {
            ...affectedState,
            categories: [...filteredCategories]
          }
        } else {
          affectedState = {
            ...affectedState,
            categories: [...affectedState.categories, changedFilter.category]
          }
        }
        props.setPriceRange({ min: selectedFilters?.priceRange[0] === 0 ? filters?.points?.min : 0, max: selectedFilters?.priceRange[1] === 0 ? filters?.points?.max : 0 })
        props.setCategory(affectedState.categories);
      } else if (changedFilter.brand) {

        const indexOfGivenBrand = affectedState.brands.indexOf(changedFilter.brand)
        if (indexOfGivenBrand > -1) {
          const filteredBrands = affectedState.brands.filter((x: any) => x !== changedFilter.brand)
          affectedState = {
            ...affectedState,
            brands: [...filteredBrands]
          }
        } else {
          affectedState = {
            ...affectedState,
            brands: [...affectedState.brands, changedFilter.brand]
          }
        }
        props.setPriceRange({ min: selectedFilters?.priceRange[0] === 0 ? filters?.points?.min : 0, max: selectedFilters?.priceRange[1] === 0 ? filters?.points?.max : 0 })
        props.setBrand(affectedState.brands);
      } else if (changedFilter.priceRange) {
        affectedState.priceRange = [changedFilter?.priceRange?.min || filters?.points?.min || 0, changedFilter?.priceRange?.max || filters?.points?.max || 0]
        props.setPriceRange({ min: affectedState.priceRange[0], max: affectedState.priceRange[1] })
      }
      return {
        ...affectedState
      };
    })
  }

  const resetFilters = (filterType: 'category' | 'brand' | 'priceRange') => {
    if (filterType === 'category') {
      setSelectedFilters(state => {
        return {
          ...state,
          categories: []
        }
      });
      props.setCategory([])
    } else if (filterType === 'priceRange') {
      setSelectedFilters(state => {
        return {
          ...state,
          priceRange: [filters.points?.min || 0, filters.points?.max || 0]
        }
      });
      props.setPriceRange({ min: filters.points?.min || 0, max: filters.points?.max || 0 })
    } else if (filterType === 'brand') {
      setSelectedFilters(state => {
        return {
          ...state,
          brands: []
        }
      });
      props.setBrand([])
    }
  }

  const doesFilterExist = (value: number, type: "categories" | "brands"): boolean | undefined => {
    const indexValue = selectedFilters[type].indexOf(value);
    return indexValue > -1;
  }

  const highestPrice = (value: number) => {
    let prices: number[] = []
    let highestPriceLength = Math.ceil(value / 5000);
    for (let priceRange = 1; prices.length < highestPriceLength; priceRange++) {
      const isPresent = prices.indexOf(priceRange * 5000) >= 0;
      if (!isPresent) {
        prices.push(priceRange * 5000);
      }
    }
    setPriceRange(prices);
  }

  useEffect(() => {
    if (token) {
      getFilters(token || '').then((res: any) => {
        const response = res?.data?.data?.data || null;
        setFilters(response);
        highestPrice(response.points.max)
      }).catch((error: any) => {
        props.showError(true)
      })
    }
  }, [token])

  return (
    <div className={`${props.cart.length > 0 ? 'h-[65vh]' : 'h-screen'} hide-scroll-bar overflow-y-auto transition-[display] bg-white shadow shadow-slate-900/10 rounded p-3`}>
      <div className='w-full flex items-baseline justify-between'>
        <h2 className='text-xl font-semibold'>Filters</h2>
        {/* <button
          onClick={applyFilters}
          // disabled={isDisabled}
          className={`text-[#0A6AA6] mx-2 disabled:opacity-50 opacity-100 `}
        >
          Apply
        </button> */}
      </div>
      <div className='my-2 p-2'>
        <div className='flex items-center justify-between p-1 cursor-pointer'>
          <h3 className="text-base font-medium">Category</h3>
          <div>
            <button disabled={selectedFilters.categories.length === 0} onClick={() => resetFilters('category')} className='text-[#0A6AA6]/75 hover:text-[#0A6AA6] disabled:text-[#0A6AA6]/50'>Clear</button>
          </div>
        </div>
        <div className={`transition-all max-h-[50vh] overflow-y-auto`}>
          {filters?.category?.map((item: any, index: number) => {
            return (
              <div key={index} className='flex items-center p-1 text-sm'>
                <input
                  checked={doesFilterExist(item.cid, "categories")}
                  type='checkbox' className='border-2 rounded-lg' name="category" onChange={(e: any) => changeFilterState({
                    category: item.cid
                  })} />
                <label className='mx-2'>{item.name}</label>
              </div>
            )
          }) || <></>}
        </div>
      </div>
      <div className='h-[2px] w-full bg-[#000000]/10 rounded-full my-1' />
      <div className='my-2 p-2'>
        <div className='flex items-center justify-between cursor-pointer p-1' >
          <h3 className="text-base font-medium">Points range</h3>
          <div>
            <button onClick={() => resetFilters('priceRange')} className='text-[#0A6AA6]/75 hover:text-[#0A6AA6] disabled:text-[#0A6AA6]/50'>Clear</button>
          </div>
        </div>
        <div className={`max-h-[50vh] overflow-y-auto transition-all`}>

          {priceRange.map((value: number, index: number) => {
            return (
              <div key={index} className='flex items-center p-1 text-sm'>
                <input onChange={(e: any) => {
                  if (value <= 5000) {
                    priceChangeFilter(filters?.points?.min || 0, 5000);
                  } else if ((filters?.points?.max || 0) < value) {
                    priceChangeFilter(value - 5000, filters?.points?.max || 0);
                  } else {
                    priceChangeFilter(value / index, value);
                  }
                }} value="" type='radio' className='border-2 rounded-lg' name="brands" />
                <label className='mx-2'>{
                  (value <= 5000) ?
                    filters?.points?.min + ' to ' + 5000 :
                    ((filters?.points?.max || 0) < value) ?
                      value - 5000 + ' to ' + filters?.points?.max || 0 :
                      value - 5000 + ' to ' + value
                }</label>
              </div>
            )
          })}

          {/* {(filters?.points?.max || 0) >= 5000 ?
            <div className='flex items-center p-1 text-sm'>
              <input onChange={(e: any) => {
                priceChangeFilter(filters?.points?.min || 0, 5000);
              }} value="" type='radio' className='border-2 rounded-lg' name="brands" />
              <label className='mx-2'>{filters?.points?.min || 0} to 5,000</label>
            </div> : <></>}
          {(filters?.points?.max || 0) >= 10000 ? <div className='flex items-center p-1 text-sm'>
            <input onChange={(e: any) => {
              priceChangeFilter(5000, 10000);
            }} value="" type='radio' className='border-2 rounded-lg' name="brands" />
            <label className='mx-2'>5,000 to 10,000</label>
          </div> : <></>}
          {(filters?.points?.max || 0) >= 15000 ? <div className='flex items-center p-1 text-sm'>
            <input onChange={(e: any) => {
              priceChangeFilter(10000, 15000);
            }} value="" type='radio' className='border-2 rounded-lg' name="brands" />
            <label className='mx-2'>10,000 to 15,000</label>
          </div> : <></>}
          {(filters?.points?.max || 0) >= 20000 ? <div className='flex items-center p-1 text-sm'>
            <input onChange={(e: any) => {
              priceChangeFilter(15000, 20000);
            }} value="" type='radio' className='border-2 rounded-lg' name="brands" />
            <label className='mx-2'>15,000 to 20,000</label>
          </div> : <></>}
          {(filters?.points?.max || 0) >= 25000 ? <div className='flex items-center p-1 text-sm'>
            <input onChange={(e: any) => {
              priceChangeFilter(20000, 25000);
            }} value="" type='radio' className='border-2 rounded-lg' name="brands" />
            <label className='mx-2'>20,000 to 25,000</label>
          </div> : <></>}
          {(filters?.points?.max || 0) > 25000 ? <div className='flex items-center p-1 text-sm'>
            <input onChange={(e: any) => {
              priceChangeFilter(25000, filters?.points?.max || 0);
            }} value="" type='radio' className='border-2 rounded-lg' name="brands" />
            <label className='mx-2'>25,000 to {filters?.points?.max || 0}</label>
          </div> : <></>} */}
          {((filters?.points?.min || 0) > 0) && ((filters?.points?.max || 0) > 0) ?
            <div className='flex items-center justify-center p-2 text-sm'>
              <input
                onBlur={(e: any) => {
                  setCustom([e.target.value, customMax === 0 ? filters.points?.max : customMax]);
                  priceChangeFilter(e.target.value, customMax === 0 ? filters?.points?.max || 0 : customMax)
                }}
                className='w-[30%] min-w-[50px] border-[1px] rounded border-[#000000]/25 mx-2 text-sm p-1'
                type="number"
                min={filters.points?.min || 0}
                max={filters.points?.max || 0}
                placeholder='min'
                defaultValue={filters?.points?.min}
              />
              <label>to</label>
              <input
                onBlur={(e: any) => {
                  setCustom([customMin === 0 ? filters?.points?.min || 0 : customMin, e.target.value]);
                  priceChangeFilter(customMin === 0 ? filters?.points?.min || 0 : customMin, e.target.value);
                }}
                className='w-[30%] min-w-[50px] border-[1px] rounded border-[#000000]/25 mx-2 text-sm p-1'
                type="number"
                min={filters.points?.min || 0}
                max={filters.points?.max || 0}
                placeholder='max'
                defaultValue={filters?.points?.max} />
            </div>
            : <></>}
        </div>
      </div>
      <div className='h-[2px] w-full bg-[#000000]/10 rounded-full my-1' />
      <div className='my-2 p-2'>
        <div className='flex items-center justify-between cursor-pointer p-1'>
          <h3 className="text-base font-medium">Brands</h3>
          <div>
            <button disabled={selectedFilters.brands.length === 0} onClick={() => resetFilters('brand')} className='text-[#0A6AA6]/75 hover:text-[#0A6AA6] disabled:text-[#0A6AA6]/50'>Clear</button>
          </div>
        </div>
        <div className={`max-h-[50vh] overflow-y-auto transition-all`}>
          {filters?.brands?.map((item: any, index: number) => {
            return (
              <div key={index} className='flex items-center p-1 text-sm'>
                <input onChange={(e: any) => changeFilterState({
                  brand: item.id
                })} checked={doesFilterExist(item.id, "brands")} type='checkbox' className='border-2 rounded-lg' name="brands" />
                <label className='mx-2'>{item.name}</label>
              </div>
            )
          }) || <></>}
        </div>
      </div>
      <LoadingScreen show={props.filterLoading} />
    </div>
  )
}

export default Filter