import React from 'react';
import './App.css';

//routes
import { BrowserRouter as Router } from 'react-router-dom';
import RouterConfig from './routes/RouterConfig';
import Navbar from './components/navigation/Navbar';

function App() {
  return (
    <div className='bg-[#F2F2F2]'>
      <Router>
        <Navbar />
        <RouterConfig />
      </Router>
      </div>
  );
}

export default App;
