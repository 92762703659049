import React from 'react'
import logo from '../../assets/logo/hindu.png'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../constants/routes'

const Logo = () => {

  const navigate = useNavigate()
  
  return (
    <div className='w-40 h-auto cursor-pointer' onClick={() => navigate(routes.home)}>
      <img style={{display: 'inline-block'}} width='100%' height='100%' src={logo} alt="hindu" />
    </div>
  )
}

export default Logo