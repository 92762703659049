import axios from "axios"
import { env } from "../../../constants/env";

export const getRewards = async (token: string, page: number = 1, filters?: {}) => {
  try {
    const data = await axios.post(`${env.BASE_URL}/client/store/reward?page=${page}`, {
      ...filters
    }, {
      headers: {
        "r-token": token,
        "X-API-KEY": env.X_API_KEY,
        "X-API-SECRET": env.X_API_SECRET
      }
    });
    return data
  } catch (e) {
    throw e;
  }
}

export const getFilters = async (token: string) => {
  try {
    const data = await axios.get(`${env.BASE_URL}/client/store/reward/filter`, {
      headers: {
        "r-token": token,
        "x-api-key": env.X_API_KEY,
        "x-api-secret": env.X_API_SECRET
      }
    });

    return data
  } catch (e) {
    throw e
  }
}

export const addReward = async (token: string, reward_id: number) => {
  try {
    const data = await axios.patch(`${env.BASE_URL}/client/store/reward/${reward_id}/add`, {}, {
      headers: {
        "r-token": token,
        "x-api-key": env.X_API_KEY,
        "x-api-secret": env.X_API_SECRET
      }
    })
    return data
  } catch (e) {
    throw e
  }
}

export const removeReward = async (token: string, reward_id: number) => {
  try {
    const data = await axios.patch(`${env.BASE_URL}/client/store/reward/${reward_id}/remove`, {}, {
      headers: {
        "r-token": token,
        "x-api-key": env.X_API_KEY,
        "x-api-secret": env.X_API_SECRET
      }
    })
    return data
  } catch (e) {
    throw e
  }
}

export const getCart = async (token: string) => {
  try {
    const data = await axios.get(`${env.BASE_URL}/client/user/cart`, {
      headers: {
        "r-token": token,
        "x-api-key": env.X_API_KEY,
        "x-api-secret": env.X_API_SECRET
      }
    })
    return data;
  } catch (e) {
    throw e
  }
}